import React, { useState} from "react";
import {
  ContactFormWrapper,
  ContactForm1,
  FormItem,
  SubmitFormBtn,
  SubmitWrapper,
} from "./ContactFormElements";
import { MdSend } from "react-icons/md";
import emailjs from "@emailjs/browser";

const ContactForm = () => {

  const [name, setName] = useState('');
  const [phone, setPhone ] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  const fullNameHandler = (e) => {
    setName(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_10lhur2",
        "template_jsxx99f",
        e.target,
        "Qi-JWX-dU5oZCRsXr"
      )
      .then((res) => {
        console.log(res);
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
        setMessageSent(true);

      })
      .catch((err) => console.log(err));

  }
  return (
    <ContactFormWrapper>
      <ContactForm1 onSubmit={sendEmail}>
        <FormItem>
          <label>Full Name</label>
          <input type="input" value={name} name="name" onChange={fullNameHandler}/>
        </FormItem>
        <FormItem>
          <label>Phone Number</label>
          <input type="input" value={phone} name="phone" onChange={phoneHandler} />
        </FormItem>
        <FormItem>
          <label>Email Address</label>
          <input type="input" value={email} name="email" onChange={emailHandler} />
        </FormItem>
        <FormItem>
          <label>Message</label>
          <textarea value={message} name="message" onChange={messageHandler}/>
        </FormItem>
        <SubmitWrapper>
          {messageSent && <div>Message Sent.</div>}
          <SubmitFormBtn>
            <MdSend />
          </SubmitFormBtn>
          <label>Send</label>
        </SubmitWrapper>
      </ContactForm1>
    </ContactFormWrapper>
  );
};

export default ContactForm;
