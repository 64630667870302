import React from 'react'
import CarrierSection from '../../Components/CarrierSection'

const Carrier = () => {
  return (
      <>
          <CarrierSection />
      </>
  )
}

export default Carrier
