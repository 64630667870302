import React, { Component } from "react";
import HeroSection from "../Components/HeroSection";
import AboutSection from "../Components/AboutSection";
import SubmissionSection from "../Components/SubmissionSection";
import ServicesSection from "../Components/ServicesSection";
import GoToTop from "../Components/GoToTop";

export class Home extends Component {
  state = {
    toDestination: 'destination',
    fromDestination: "",
    vehicleYear: "",
    vehicleMake: "",
    vehicleModel: "",
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const {
      toDestination,
      fromDestination,
      vehicleMake,
      vehicleModel,
      vehicleYear,
    } = this.state;

    const values = { toDestination, fromDestination, vehicleMake, vehicleModel, vehicleYear }; 
    return (
<>
        <HeroSection
          handleChange={this.handleChange}
          values={values} />
        <AboutSection />
        <ServicesSection />
        <SubmissionSection />
        <GoToTop />
        </>
    );
  }
}

export default Home;
