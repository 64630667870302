import React, { useState} from "react";
import { ShipperFormWrap, ShipForm } from "./ShipperFormElements";
import { TextField } from "@mui/material";
import { Button } from "../../ButtonElements";
import emailjs from "@emailjs/browser";

const ShipperForm = (props) => {
   
    const [toZip, setToZip] = useState(props.toZip);
    const [fromDestination, setFromDestination] = useState("");
    const [vehicleYear, setVehicleYear] = useState("");
    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [phone, setPhone] = useState("");
    const [desiredDate, setDesiredDate] = useState("");
    const [messageSent, setMessageSent] = useState(false);

    const emailRegex = /\S+@\S+\.\S+/;

    const toDestinationHandler = (e) => {
        setToZip(e.target.value);
    };

    const fromDestinationHandler = (e) => {
        setFromDestination(e.target.value);
    };

    const vehicleYearHandler = (e) => {
        setVehicleYear(e.target.value);
    };
    
    const vehicleMakeHandler = (e) => {
        setVehicleMake(e.target.value);
    };

    const vehicleModelHandler = (e) => {
        setVehicleModel(e.target.value);
    };

    const fullNameHandler = (e) => {
        setFullName(e.target.value);
    };

    const emailHandler = (e) => {
        const email = e.target.value;
        if (emailRegex.test(email) || email == "") {
            setValidEmail(true);
        }
        else {
            setValidEmail(false);
        }
        setEmail(e.target.value);
    };

    const phoneHandler = (e) => {
        setPhone(e.target.value);
    };
    const desiredDateHandler = (e) => {
        setDesiredDate(e.target.value);
    };


    const sendEmail = (e) => {
        e.preventDefault();
            emailjs
                .sendForm(
                    "service_10lhur2",
                    "template_t5519tc",
                    e.target,
                    "Qi-JWX-dU5oZCRsXr"
                )
                .then((res) => {
                    console.log(res);
                    setToZip("");
                    setFromDestination("");
                    setVehicleYear("");
                    setVehicleMake("");
                    setVehicleModel("");
                    setFullName("");
                    setEmail("");
                    setPhone("");
                    setDesiredDate("");
                    setMessageSent(true);
                })
                .catch((err) => console.log(err));
    
    };
        return (
            <ShipperFormWrap >
                <ShipForm onSubmit={sendEmail}>
                    <TextField required variant="filled" label="From Destination" name="toDestination" value={fromDestination} onChange={fromDestinationHandler}  />
                    <TextField required variant="filled" label="To Destination" name="fromDestination" value={toZip} onChange={toDestinationHandler} />
                    <TextField required variant="filled" label="Vehicle Year" name="vehicleYear" value={vehicleYear} onChange={vehicleYearHandler}/>
                    <TextField required variant="filled" label="Vehicle Make"  name="vehicleMake" value={vehicleMake} onChange={vehicleMakeHandler}/>
                    <TextField required variant="filled" label="Vehicle Model"  name="vehicleModel" value={vehicleModel} onChange={vehicleModelHandler} />
                    <TextField required variant="filled" label="Name" name="name"defaultValue={fullName} onChange={fullNameHandler}/>
                    <TextField required variant="filled" label="Email" name="email" value={email} onChange={emailHandler}/>
                    <TextField required variant="filled" label="Phone" name="phone" value={phone} onChange={phoneHandler}/>
                    <TextField required variant="filled" label="Preferred Date" name="date" value={desiredDate} onChange={desiredDateHandler}/>
                    <p>
                        Shepherd's Auto Transport will use your information to contact you about our products
                        and services. You may unsubscribe from these communications at any
                        time.
                    </p>
                    <Button primary={true}>Submit</Button>
                </ShipForm>
            </ShipperFormWrap>
        );
    };
export default ShipperForm;
