import React, { useState } from 'react'
import {
  HeroContainer,
  HeroBg,
  HeroImage,
  HeroContent, 
  HeroH1, 
  ShipFromInput,
  GetFreeQuoteBtn, 
  FormInputs
} from "./HeroElements";
import Image from '../../images/hero_bg.png'
import { RouteButton } from '../ButtonElements';
import Shipper from '../../Pages/shipper'


const HeroSection = (props) => {
  return (
    <HeroContainer>
    <HeroBg>
      <HeroImage src={Image} loading="lazy" />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          A streamlined and simplified shipping process. 
        </HeroH1>
        {/* <RouteButton primary={true} to="/shippers">Get A Free Quote</RouteButton> */}
      {/* <ShipFromInput>
        <label>Where is your vehicle being shipped from?</label>
        <FormInputs>
            <input type="text" placeholder="Zip Code" onChange={handleChange} />
            <RouteButton to={{
              pathname: '/shippers', 
              state: {
                toZip: toZip
              }
            }} primary={"true"} >Get Free Quote</RouteButton>
          </FormInputs>
      </ShipFromInput> */}
      </HeroContent>

  </HeroContainer>
  )
}

export default HeroSection

