import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const SideBarContainer = styled.div`
width: 100%; 
height: 100vh;
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? "0" : "-8000px")};
  transition: all 0.4s ease-in-out;
  z-index: 999;
  background-color: white;
  display: flex;
  justify-content: center;
`;
export const SideBarMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 75px);
  align-items: center;
  justify-content: center;
  width: min(300px, 50%);
gap: 1rem;
`;
export const SideBarItem = styled.div`
  display: flex;
  border-bottom: 1px solid #d1d1d1;
  padding: 1rem;
  justify-content: center;
  max-width: 300px;
  &:hover {
    border-bottom: 1px solid #3143BE;
    transition: all 0.2s ease-in;
  }
`;

export const SideBarLink = styled(LinkR)`
  text-decoration: none;
  text-transform: uppercase;
  color: #0f044c;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
`;

export const SideBarText = styled.div`
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SideBarH3 = styled.h3`
  font-family: "Oxygen";
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #0f044c;
  text-align: center;
`;

export const SideBarH2 = styled.h2`
  font-family: "Oxygen";
  font-size: clamp(1.2rem, 3rem);
  color: #0f044c;
  margin-top: -10px;
  text-align: center;
`;
