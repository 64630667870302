import React from 'react'
import ContactSection from '../../Components/ContactSection'

const Contact = () => {
  return (
      <>
          <ContactSection />
      </>
  )
}

export default Contact
