import React from "react";
import {
  ServicesContainer,
  ServicesBg,
  ServicesImage,
  ServicesContent,
  ServicesH1,
  ServicesP,
} from "./ServicesElements";
import Image from "../../images/more_services.png";

const ServicesSection = () => {
  return (
    <ServicesContainer>
      <ServicesBg>
        <ServicesImage src={Image} loading="lazy" />
      </ServicesBg>
      <ServicesContent>
        <ServicesH1>Services for every kind of trucker. </ServicesH1>
        <ServicesP>
          We couldn't forget about our CDL drivers and owner operators. The
          trucking industry is as successful as is because everyone keeps the
          machine well oiled and turning. In addition to exceptional auto
          transport services, we offer top of the line CDL driver recruitment and
          placement services. 
        </ServicesP>
      </ServicesContent>
    </ServicesContainer>
  );
};

export default ServicesSection;
