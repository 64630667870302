import React from "react";
import { LearnMoreButton } from "./AboutSectionElements";
import {
  AboutContainer,
  AboutBg,
  MissionWrapper,
  AboutHeader,
  MissionText,
} from "./AboutSectionElements";
const AboutSection = () => {
  return (
    <AboutContainer>
      <AboutBg />
      <MissionWrapper>
        <AboutHeader>
          Moving shipping and logistics into the future.
        </AboutHeader>
        <MissionText>
          Every time a customer ships an automobile with{" "}
          <b>Shepherd's Auto Transport</b>, they receive the best service
          possible. We add value for our clients by providing tailored solutions
          that satisfy their needs, no matter how complex they may be. Get the{" "}
          <b>quickest</b>, <b>safest</b>, and <b>most affordable</b> method to
          transport your car by requesting a free, no-obligation estimate today.
        </MissionText>
        <LearnMoreButton to="shippers">Learn More</LearnMoreButton>
      </MissionWrapper>
    </AboutContainer>
  );
};

export default AboutSection;
