import React from "react";
import {
  SubmissionContainer,
  SubmissionTitle,
  SubButtonsWrap,
  SubmissionButton,
} from "./SubmissionSectionElements";
import { AiFillCar } from "react-icons/ai";
import { FaRoute, FaTruck } from "react-icons/fa";
const SubmissionSection = () => {
  return (
    <SubmissionContainer>
      <SubmissionTitle>Let Us Help You</SubmissionTitle>
      <SubButtonsWrap>
        <SubmissionButton className="shipping" to="/shippers">
          <AiFillCar size={75} />
          Shippers
        </SubmissionButton>
        <SubmissionButton className="carrier" to="/carriers">
          <FaRoute size={70} />
          Carriers
        </SubmissionButton>
        <SubmissionButton className="driver" to="/drivers">
          <FaTruck size={75} />
          Drivers
        </SubmissionButton>
      </SubButtonsWrap>
    </SubmissionContainer>
  );
};

export default SubmissionSection;
