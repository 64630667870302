import React from "react";
import { useLocation } from "react-router-dom";
import GoToTop from "../../Components/GoToTop";
import ShipperSection from "../../Components/ShipperSection";
import { ShipperContainer } from "./ShipperElements";

const Shipper = (props) => {

  
  return (
    
    <ShipperContainer>
      <ShipperSection/>
      <GoToTop/>
    </ShipperContainer>
  );
};

export default Shipper;
