import React, { Component } from "react";
import { CarrierContainer, CarrierBg, CarrierImage, CarrierContent, CarrierH1, CarrierFormWrapper} from "./CarrierSectionElements"
import Image from "../../images/carrier_bg.png"
import { ScrollButton } from "../ButtonElements"
import InfoSection from "./InfoSection";
import CarrierForm from "./CarrierForm";
import {
  homeObjOne,
  homeObjTwo,
} from "./InfoSection/Data"
import Image1 from '../../images/cdl_license.png'
import Image2 from '../../images/classroom.png'
import GoToTop from "../GoToTop";

export class CarrierSection extends Component {
  render() {
    return (
      <>
      <CarrierContainer id="carriers">
        <CarrierBg>
        <CarrierImage src={Image} loading="lazy"/>
        </CarrierBg>
        <CarrierContent >
          <CarrierH1>
          Tap into a powerful network of drivers. 
          </CarrierH1>
            <ScrollButton primary="true" offset={-300} spy={true} smooth={true} duration={500} to="cform">Find A Driver</ScrollButton>
        </CarrierContent >
      </CarrierContainer>
        <InfoSection {...homeObjOne} img={Image1} />
        <InfoSection {...homeObjTwo} img={Image2} />
        <CarrierFormWrapper id="cform">
          <h3>Request CDL Driver Placement</h3>
          <CarrierForm />
        </CarrierFormWrapper>
        <GoToTop />
      </>
    );
  }
}
export default CarrierSection;
