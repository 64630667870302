import styled from "styled-components"

export const ServicesContainer = styled.div`
display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  position: relative;

  z-index: 1;
  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`

export const ServicesBg = styled.div`

position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;`

export const ServicesImage = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  `

export const ServicesContent = styled.div`
 max-width: 800px;
  z-index: 3;
  padding: 45px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: -100px;
  position: relative;

  @media screen and (min-width: 630px){
   align-items: flex-end;
 }
`
  
export const ServicesH1 = styled.h1`

color: #fff;
  font-size: 2rem;
  text-align: center;
  font-family: "Oxygen";
  line-height: 1.2;
  max-width: 500px;

  @media screen and (min-width: 630px){
    text-align: right;
 }`

export const ServicesP = styled.div`
color: white; 
font-family: 'Oxygen';
font-size: 1.2rem;
text-align: center;
max-width: 800px;

@media screen and (min-width: 630px){
   text-align: right;
 }

`