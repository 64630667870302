import styled from "styled-components";
import { Link as LinkS} from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

export const ScrollButton = styled(LinkS)`
background: ${({primary}) => primary ? "#3143BE" : 'none'};
border: none; 
color: white; 
font-family: 'Oxygen';
padding: 1rem;
white-space: nowrap;
cursor: pointer;

`;

export const RouteButton = styled(LinkR)`
background: ${({ primary }) => primary ? "#3143BE" : 'none'};
border: ${({primary}) => primary ?  'none' : "1px solid #3143BE"};
color: ${({ primary }) => primary ? 'white' : "#3143BE"};
text-decoration: none;
font-family: 'Oxygen';
padding: 1rem;
white-space: nowrap;
z-index: 3;
text-align: center;
`;

export const Button = styled.button`
background: ${({ primary }) => primary ? "#3143BE" : 'none'};
border: ${({primary}) => primary ?  'none' : "1px solid #3143BE"};
border: none;
color: white;
font-family: 'Oxygen';
padding: 1rem;
white-space: nowrap;
z-index: 3;
`;
