export const homeObjOne = {
  id: "about",
  to: "form",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Quality Drivers",
  headline: "CDL Recruitment and Staffing",
  description:
    "Shepherd’s Auto Transport provides recruitment services for CDL Drivers, and Auto Carriers, and Owner Operators. We utilize our large networks of CDL drivers to provide your business with the most equipped drivers.",
  buttonLabel: "Get Drivers Now",
  imgStart: false,
  alt: "client portal",
  dark: false,
  primary: "true",
  darkText: true,
};

export const homeObjTwo = {
  id: "about",
  to: "cform",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Wide Variety",
  headline: "From Expert to Entry Level Drivers",
  description:
    "In addition to connecting you with some of the most experienced drivers in the industry - advertise, hire, and staff from our network of CDL schools.",
  buttonLabel: "Submit Route Details",
  imgStart: true,
  alt: "client portal",
  dark: true,
  primary: "true",
  darkText: false,
};

export const shipperObjOne = {
  id: "quality",
  to: "sform",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Dedicated Advisors",
  headline: "Exceptional Customer Support ",
  description:
    "We work passionately and effectively to transport your automobile from point A to point B, monitoring your shipment around the clock.",
  buttonLabel: "Get A Free Quote",
  imgStart: false,
  alt: "auto transport",
  dark: false,
  primary: "true",
  darkText: true,
};

export const shipperObjTwo = {
  id: "insurance",
  to: "sform",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Full Protection",
  headline: "Insurance Coverage",
  description:
    "Your piece of mind is our top priority. Our selected haulers meet all insurance standards, thus guaranteeing protection for you and your vehicle.",
  buttonLabel: "Get A Free Quote",
  imgStart: true,
  alt: "insurance",
  dark: true,
  primary: "true",
  darkText: false,
};


  export const driverObjOne = {
    id: "info",
    to: "dform",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Get Recruited",
    headline: "Start Driving Sooner",
    description:
      "Learn more about all the ways that Shepherd's Auto Transport goes the extra mile to provide financial stability, flexible home time, and more to our drivers",
    buttonLabel: "Find A Route",
    imgStart: true,
    alt: "insurance",
    dark: true,
    primary: "true",
    darkText: true,
  };

  export const driverObjTwo = {
    id: "insurance",
    to: "dform",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "Top Notch Recruiters",
    headline: "Find A Better Career",
    description:
      "You can expect a call from one of our best recruiters in 1-2 business days.",
    buttonLabel: "Find A Route",
    imgStart: false,
    alt: "recruiter",
    dark: true,
    primary: "true",
    darkText: false,
  };