import React from "react";
import {
  Nav,
  NavBarContainer,
  LogoWrapper,
  Logo,
  NavMenu,
  NavItem,
  NavLink,
  QuoteButton,
  MobileIcon,
  CallNow,
} from "./NavBarElements";
import Image from "../../images/shep_logo.png";
import { FaBars } from "react-icons/fa";

const NavBar = ({ isOpen, toggle }) => {
  return (
    <Nav isOpen={isOpen}>
      <CallNow>
        <h6>
          Call <u>1-888-828-7328</u> today!
        </h6>
      </CallNow>
      <NavBarContainer>
        <MobileIcon onClick={toggle} isOpen={isOpen}>
          <FaBars />
        </MobileIcon>
        <LogoWrapper to="/">
          <Logo src={Image} loading="lazy" />
        </LogoWrapper>
        <NavMenu>
          <NavItem>
            <NavLink to="shippers">
              {" "}
              For <b>Shippers</b>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/carriers">
              For <b>Carriers</b>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/drivers">
              For <b>Drivers</b>
            </NavLink>
          </NavItem>
        </NavMenu>
        <QuoteButton to="/shippers/sform">Get A Quote</QuoteButton>
      </NavBarContainer>
    </Nav>
  );
};

export default NavBar;
