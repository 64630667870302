import styled from "styled-components";


export const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  position: relative;
  z-index: 1;
  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;

`
export const HeroInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "a" "b";
  align-items: center;
  max-width: 800px;
  padding: 30px 20px;
  @media screen and (min-width: 850px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "a b";
  }
`;

export const HeroContent = styled.div`
  max-width: 800px;
  z-index: 3;
  padding: 45px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: -100px;
  position: relative;

  @media screen and (min-width: 630px){
   align-items: flex-start;
 }
;

`;

export const HeroH1 = styled.h1`
color: #fff;
  font-size: clamp(1rem, 2rem, 5rem);
  text-align: center;
  font-family: "Oxygen";
  line-height: 1.2;
  max-width: 500px;

  @media screen and (min-width: 630px){
    text-align: left;
 }
`;

export const ShipFromInput = styled.form`
display: flex; 
flex-direction: column;
align-items: center;
padding: 20px;
gap: 1rem;
/* From https://css.glass */
background: rgba(177, 177, 177, 0.54);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.1px);
-webkit-backdrop-filter: blur(9.1px);
& label {
  color: white;
  text-align: center;
}
 & input {
   font-size: 1.2rem;
   font-family: 1rem;
   color: #3143BE;
   padding: .5rem;

  &::placeholder {
   color: #d1d1d1;
 }
 }

 @media screen and (min-width: 630px){
  align-items: flex-start;
 }
`

export const FormInputs = styled.div`
display: flex;
flex-direction: column;

@media screen and (min-width: 630px){
  flex-direction: row;
 }
gap: 1rem;

`
export const GetFreeQuoteBtn = styled.button`
background: #3143BE;
border: none; 
color: white; 
font-family: 'Oxygen';
padding: .5rem;
white-space: nowrap;
`

