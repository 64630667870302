import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const Nav = styled.div`
  /* From https://css.glass */
 background-color: white;
  margin: 0 auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  position: sticky;
  left: 0;
  right: 0;
  transform: translate(0%, 0);
  top: 0;
  z-index: 999;
  transition: all 0.2s ease-in-out;
`;

export const CallNow = styled.div`
background-color: #3143be;
width: 100%; 
color: white;
display: flex;
justify-content: center;

& h6 {
  font-size: 1rem;
}
`
export const NavBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;

export const MobileIcon = styled.div`
  color: ${({ isOpen }) => (isOpen ? "#3143BE" : "#0f044c")};

  @media screen and (min-width: 850px) {
    display: none;
  }
`;

export const LogoWrapper = styled(LinkR)`
  max-width: 215px;
  min-width: 175px;
`;
export const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

export const NavMenu = styled.div`
  display: none;
  @media screen and (min-width: 850px) {
    display: flex;
    align-items: center;
  }
`;

export const NavItem = styled.div`
  border-right: 1px solid #e1e1e1;
  padding: 0 10px;


  &:hover {
    transition: all 0.2s ease;
    border-right: 3px solid #3143be;
  }
`;

export const NavLink = styled(LinkR)`
  text-transform: uppercase;
  font-family: "Oxygen";
  text-decoration: none;
  color: #0f044c;
  word-spacing: -1px;
  white-space: nowrap;

`;

export const QuoteButton = styled(LinkR)`
  font-family: "Oxygen";
  background-color: #3143be;
  color: #fff;
  padding: 10px 35px;
  border: none;
text-decoration: none;
white-space: nowrap;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #0f044c;
  }
`;
