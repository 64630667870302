import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const AboutContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AboutBg = styled.div`
  background-color: #dfdfdf;
`;
export const MissionWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
`;

export const AboutHeader = styled.h2`
  font-size: 2rem;
  color: #3143BE;
  text-align: center;
  font-family: "Oxygen";
`;

export const MissionText = styled.p`
  text-align: justify;
  text-align-last: center;
  padding: 10px 10px;
  font-size: 1.2rem;
  font-family: "Oxygen";
  color: #0F044C;
`;

export const LearnMoreButton = styled(LinkR)`
  border: none;
  color: #3143BE;
  text-decoration: underline;
  font-family: "Oxygen";
  padding: 10px;
  max-width: 400px;
  font-size: 1rem;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #3143BE;
  }
`;

