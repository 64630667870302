import styled from "styled-components";

export const ShipperFormWrap = styled.div`
  background: #141e61;
  width: min(600px, 80%);
  padding: 1rem;
`;

export const ShipForm = styled.form`
display: flex;
flex-direction: column;
gap: 1rem;
color: white;
text-align: left;
  & label {
      font-size: 1rem;
      color: #141e61
  }
  & input {
      background-color: #dfdfdf;
      border: none;
      font-size: 1.2rem;
  }

& p {
    font-size: .8rem;
}
`;

export const ShipItem = styled.div`
  display: flex;
  flex-direction: column;

`;
