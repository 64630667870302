import styled from "styled-components";

export const ContactFormWrapper = styled.div`

`;

export const ContactForm1 = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  & label {
    color: white;
    font-family: "Oxygen";
    font-size: 0.8rem;
    width: 100%;
  }

  & input {
    color: #0F044C;
    font-family: "Oxygen";
    font-size: 1rem;
    border-bottom: 1px solid white;
    background: #fff;
    border: none;
    width: 100%;
  }

  & textarea {
    border: none;
    background: #fff;
    width: 100%;
    min-height: 200px;
    margin-top: 10px;
    font-family: "Oxygen";
    font-size: 1rem;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
`;

export const SubmitFormBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background: #3143BE;
  border: none;
  color: white;
  font-size: 1rem;
  font-family: "Oxygen";
  padding: 10px;
  border-radius: 50px;

  &:hover {
    background: white;
    color: #3143BE;
    transition: all 0.2s ease-in-out;
  }
`;

export const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;

  & div {
    color: white;
    padding: 1rem;
  }
  & label {
    color: white;
    text-align: center;
  }
`;
