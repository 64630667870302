import React from 'react'
import DriverSection from '../../Components/DriverSection'
import GoToTop from '../../Components/GoToTop'

const Driver = () => {
  return (
    <>
      <DriverSection />
      <GoToTop />
      </>
  )
}

export default Driver
