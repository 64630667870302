import React from "react";
import {
  FooterContainer,
  FooterWrapper,
  Col1,
  Col2,
  Col3,
  PopularLinksWrapper,
  Link,
  MapLink,
  ContactSection,
  ContactWrapper,
  LogoWrapper,
  Logo,
  Copyright,
  ContactInformation,
  FooterHeader
} from "./FooterElements";
import ContactForm from "./ContactForm/";
import { BsFillPhoneVibrateFill } from "react-icons/bs";
import { MdMarkEmailUnread } from "react-icons/md";
import { AiFillFacebook } from "react-icons/ai";
import Image from "../../images/shep_logo_white.png";

const FooterSection = () => {
  return (
    <>
      <FooterContainer id="footer">
        <FooterWrapper>
          <Col1>
            <FooterHeader>Contact Us</FooterHeader>
          <ContactForm/>
        </Col1>
          <Col2>
          <FooterHeader>Site Map</FooterHeader>
            <PopularLinksWrapper>
           
            <Link>
              <MapLink to="/shippers">Get a Free Quote</MapLink>
            </Link>
            <Link>
              <MapLink to="/shippers">Services for Shippers</MapLink>
            </Link>
            <Link>
              <MapLink to="/carriers">Services for Carriers</MapLink>
            </Link>
            <Link>
              <MapLink to="/drivers">Services for CDL Drivers</MapLink>
            </Link>
          </PopularLinksWrapper>
        </Col2>
        <Col3>
            <ContactWrapper>
            <FooterHeader>Get in Touch</FooterHeader> 
            <ContactSection>
                <ContactInformation>1-888-828-7328</ContactInformation>
                <BsFillPhoneVibrateFill color={"#3143BE"} size={40} />
            </ContactSection>
            <ContactSection> 
              
                <ContactInformation>shep@shepsautotransport.com</ContactInformation>
                <MdMarkEmailUnread color={"#3143BE"} size={40} />
            </ContactSection>
            <ContactSection>
               
                <ContactInformation>/shepherdsautotransport</ContactInformation>
                <AiFillFacebook color={"#3143BE"} size={40} />
            </ContactSection>
          </ContactWrapper>
          <LogoWrapper>
            <Logo src={Image} />
          </LogoWrapper>
          </Col3>
          </FooterWrapper>
      </FooterContainer>
      <Copyright>
        <label>
          © 2022 All rights reserved. Created by MOCREATE, LLC. with ♥.</label>
      </Copyright>
    </>
  );
};

export default FooterSection;
 