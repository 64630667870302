import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import React, { Component } from "react";
import {} from "./ShipperSectionElements";
import Image from "../../images/shipper_bg.png";
import { ScrollButton } from "../ButtonElements";
import {
  ShipperContainer,
  ShipperBg,
  ShipperImage,
  ShipperContent,
  ShipperH1,
  ShipperFormWrapper,
} from "./ShipperSectionElements";
import InfoSection from "../CarrierSection/InfoSection";
import {
  shipperObjOne,
  shipperObjTwo,
} from "../CarrierSection/InfoSection/Data";
import Image1 from "../../images/auto_transport.png";
import Image2 from "../../images/insurance.png";
import ShipperForm from "./ShipperForm";
import GoToTop from "../GoToTop";

const ShipperSection = () => {
  return (
    <>
      <ShipperContainer id="shipper_form">
        <ShipperBg>
          <ShipperImage src={Image} loading="lazy" />
        </ShipperBg>
        <ShipperContent>
          <ShipperH1>
            Fast, affordable, and reliable transport services.
          </ShipperH1>
          <ScrollButton
            primary="true"
            to="sform"
            spy={true}
            smooth={true}
            offset={-300}
            duration={500}
          >
            Get A Free Quote
          </ScrollButton>
        </ShipperContent>
      </ShipperContainer>
      <InfoSection {...shipperObjOne} img={Image1} />
      <InfoSection {...shipperObjTwo} img={Image2} />
      {/* <Routes>
          <Route path="sform" element={<ShipperForm />} />
        </Routes> */}
      <ShipperFormWrapper id="sform">
        <h3>Get A Free Quote Today</h3>
        <ShipperForm />
      </ShipperFormWrapper>
      <GoToTop />
    </>
  );
};

export default ShipperSection;
