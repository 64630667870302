import React, { Component } from "react";
import {
  DriverContainer,
  DriverContent,
  DriverBg,
  DriverImage,
  DriverH1,
  DriverFormWrapper,
 
} from "./DriverSectionElements";
import Image from "../../images/driver_bg.png";
import Image1 from "../../images/recruitment.png"
import Image2 from "../../images/driving.png"
import { ScrollButton } from "../ButtonElements";
import DriverForm from "./DriverForm";
import { driverObjOne, driverObjTwo } from "../CarrierSection/InfoSection/Data";
import GoToTop from "../GoToTop";
import InfoSection from "../CarrierSection/InfoSection";

export class DriverSection extends Component {
  render() {
    return (
      <>
      <DriverContainer>
        <DriverBg>
          <DriverImage src={Image} loading="lazy" />
        </DriverBg>
          <DriverContent>
            <DriverH1>A job that matches you.</DriverH1>
            <ScrollButton
              primary="true"
              offset={-300}
              spy={true}
              smooth={true}
              duration={500}
              to="dform"
            >
              Find A Route
            </ScrollButton>
          </DriverContent>
        </DriverContainer>
        <InfoSection {...driverObjOne} img={Image1} />
        <InfoSection {...driverObjTwo} img={Image2} />
        {/* <DriverFeatures>
          <FeatureItem>
            <h3>Versatile Routes</h3>
          </FeatureItem>
          <FeatureItem>
            <h3>Fast Placement</h3>
          </FeatureItem>
          <FeatureItem>
            <h3>Easy Process </h3>
          </FeatureItem>
          <FeatureItem>
            <h3>Great Pay</h3>
          </FeatureItem>
        </DriverFeatures> */}
        <DriverFormWrapper id="dform">
          <h2>Get Started Today</h2>
          <DriverForm />
        </DriverFormWrapper>
        <GoToTop />
        </>
    );
  }
}
export default DriverSection;
