import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const FooterContainer = styled.div`
  background: #0f044c;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export const FooterWrapper = styled.div`
padding: 1rem;
gap: 3rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas:
    "col1"
    "col2"
    "col3";
  max-width: 1000px;
  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "col1 col2 col3";
    align-items: flex-start;
    gap: 10px;
  }
`;

export const FooterHeader = styled.h2`
  color: white;
  font-family: 1rem;
  text-align: center;
  text-transform: uppercase;
`;

export const Col1 = styled.div`
  grid-area: col1;
`;

export const Col2 = styled.div`
  grid-area: col2;
`;

export const Col3 = styled.div`
  grid-area: col3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopularLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
`;
export const Link = styled.div``;

export const MapLink = styled(LinkR)`
  color: white;
  font-family: "Oxygen";
  font-size: 1rem;
  text-align: center;
`;

export const ContactSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 500px;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 700px) {
    align-items: flex-end;
  }
`;

export const LogoWrapper = styled.div`
  width: 70%;
  margin-top: 30px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

export const Copyright = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  & label {
    padding: 10px 0;
    color: grey;
    text-align: center;
    font-size: 12px;
  }
`;

export const ContactInformation = styled.p`
  color: white;
  font-size: 1rem;
`;
