import React from 'react'

const ContactSection = () => {
  return (
    <div>
      This is the contact section
    </div>
  )
}

export default ContactSection
