import styled from "styled-components";

export const DriverContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  position: relative;
  z-index: 1;
  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const DriverContent = styled.div`
  max-width: 800px;
  z-index: 3;
  padding: 45px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: -100px;
  position: relative;

`


export const DriverIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  @media screen and (min-width: 750px){
    grid-template-columns: 1fr 1fr;   
  }
`;

export const DriverDescription = styled.div`
max-width: 400px;


`;

export const DriverBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;


export const DriverImage = styled.img`
   width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const DriverFeatures = styled.div`
display: grid;
grid-template-columns: 1fr;
align-items: center;
justify-content: center;
padding: 1rem;
gap: 1rem;

@media screen and (min-width: 600px){
    grid-template-columns: 1fr 1fr;   
  }

  @media screen and (min-width: 900px){
    grid-template-columns: 1fr 1fr 1fr 1fr;   
  }

`

export const FeatureItem = styled.div`
max-width: 200px;
border: 2px solid #3143BE; 
background: none;
padding: 2rem;
border-radius: 1rem;
color: white;
text-align: center;
white-space: nowrap;
`

export const DriverFormWrapper = styled.div`
padding: 2rem;
display: flex;
flex-direction: column;
align-items: center;

& h2 {
  color: #141e61;
}
`

export const DriverH1 = styled.h2`
color: #fff;
  font-size: clamp(1rem, 2rem, 5rem);
  text-align: center;
  font-family: "Oxygen";
  line-height: 1.2;
  max-width: 500px;

`
