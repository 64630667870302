import styled from "styled-components";
import img from "../../images/grey_bg.png";
import { Link as LinkR} from "react-router-dom"

export const SubmissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem;
`;

export const SubmissionTitle = styled.h2`
  text-transform: uppercase;
  color: #3143BE;
  border: 2px solid #3143BE;
  max-width: 400px;
  padding: 10px 30px;
  text-align: center;
`;

export const SubButtonsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 40px 20px;
  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const SubmissionButton = styled(LinkR)`
  color: white;
  border: none;
  padding: 50px 50px;
  font-size: 1rem;
  font-family: "Oxygen";
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  &.shipping {
    background-color: #3143be;
  }

  &.carrier {
    background-color: #141e61;
  }

  &.driver {
    background-color: #0f044c;
  }
`;
