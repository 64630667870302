import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SideBar from "../src/Components/Navigation/SideBar";
import NavBar from "../src/Components/Navigation";
import Home from "./Pages";
import Shipper from "./Pages/shipper";
import Driver from "./Pages/driver";
import Carrier from "./Pages/carrier";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FooterSection from "./Components/Footer";
import Contact from "./Pages/contact";
import ShipperForm from "./Components/ShipperSection/ShipperForm";
import ShipperSection from "./Components/ShipperSection";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [toZip, setToZip] = useState("");

  const handleZipChange = (e) => {
    setToZip(e.target.value);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <NavBar toggle={toggle} isOpen={isOpen} />
          <SideBar toggle={toggle} isOpen={isOpen} />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  props={{ toZip: toZip, handleZipChange: { handleZipChange } }}
                />
              }
            />
            <Route path="/shippers/*" element={<Shipper />} />
            <Route path="/carriers" element={<Carrier />} />
            <Route path="/drivers" element={<Driver />} />
            <Route path="/contact_us" element={<Contact />} />
          </Routes>
          <FooterSection />
        </Router>
      </LocalizationProvider>
    </>
  );
}

export default App;
