import React, { useState } from "react";
import { DriverFormWrap, DrivForm } from "./DriverFormElements";
import { TextField } from "@mui/material";
import { Button } from "../../ButtonElements";
import emailjs from "@emailjs/browser";

const DriverForm = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [licenseType, setLicenseType] = useState("");
  const [payGoal, setPayGoal] = useState("");
  const [zipCode, setZipCode] = useState("");

  const fullNameHandler = (e) => {
    setFullName(e.target.value);
  };
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const mobilePhoneHandler = (e) => {
    setMobilePhone(e.target.value);
  };
  const licenseTypeHandler = (e) => {
    setLicenseType(e.target.value);
  };
  const payGoalHandler = (e) => {
    setPayGoal(e.target.value);
  };
  const zipCodeHandler = (e) => {
    setZipCode(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_10lhur2",
        "template_zy1aiz8",
        e.target,
        "Qi-JWX-dU5oZCRsXr"
      )
      .then((res) => {
        console.log(res);
        setFullName("");
        setEmail("");
        setMobilePhone("");
        setLicenseType("");
        setPayGoal("");
        setZipCode("");
      })
      .catch((err) => console.log(err));
  };
  return (
    <DriverFormWrap>
      <DrivForm onSubmit={sendEmail}>
        <TextField
          required
          variant="filled"
          label="Full Name"
          name="fullName"
          value={fullName}
          onChange={fullNameHandler}
        />
        <TextField
          required
          variant="filled"
          label="Email"
          name="email"
          value={email}
          onChange={emailHandler}
        />
        <TextField
          required
          variant="filled"
          label="Phone Number"
          name="phone"
          value={mobilePhone}
          onChange={mobilePhoneHandler}
        />
        <TextField
          required
          variant="filled"
          label="Commercial License Type"
          name="licenseType"
          value={licenseType}
          onChange={licenseTypeHandler}
        />
        <TextField
          required
          variant="filled"
          label="Weekly Pay Goal"
          name="payGoal"
          value={payGoal}
          onChange={payGoalHandler}
        />
        <TextField
          required
          variant="filled"
          label="Zip Code"
          name="zipCode"
          value={zipCode}
          onChange={zipCodeHandler}
        />
        <p>
          Shepherd's Auto Transport will use your information to contact you
          about our products and services. You may unsubscribe from these
          communications at any time.
        </p>
        <Button type="submit" primary={true}>Submit</Button>
      </DrivForm>
    </DriverFormWrap>
  );
};

export default DriverForm;
