import React, {useState} from "react";
import { CarrierFormWrap, CarrForm } from "./CarrierFormElements";
import { TextField } from "@mui/material";
import { Button } from "../../ButtonElements";
import emailjs from "@emailjs/browser";

const CarrierForm = () => {

  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [numTrucks, setNumTrucks] = useState('');
  const [routeType, setRouteType] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dotNum, setDotNum] = useState('');
  
  const fullNameHandler = (e) => {
    setFullName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  }; 

  const companyNameHandler = (e) => {
    setCompanyName(e.target.value);
  };
  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };
  const numTruckseHandler = (e) => {
    setNumTrucks(e.target.value);
  };
  const routeTypeHandler = (e) => {
    setRouteType(e.target.value);
  };
  const dotNumHandler = (e) => {
    setDotNum(e.target.value);
  };
  
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_10lhur2",
        "template_d73p3ym",
        e.target,
        "Qi-JWX-dU5oZCRsXr"
      )
      .then((res) => {
        console.log(res);
        setFullName('');
        setCompanyName('');
        setNumTrucks('');
        setRouteType('');
        setEmail('');
        setPhone('');
        setDotNum('');

      })
      .catch((err) => console.log(err));

  }
  return (
    <CarrierFormWrap>
      <CarrForm onSubmit={sendEmail}>
        <TextField required variant="filled" label="Full Name" name="name" value={fullName} onChange={fullNameHandler} />
        <TextField required variant="filled" label="Company Name" name="companyName" value={companyName} onChange={companyNameHandler}/>
        <TextField required variant="filled" label="Number of Trucks" name="numTrucks" value={numTrucks} onChange={numTruckseHandler}/>
        <TextField required variant="filled" label="Route Type"name="routeType" value={routeType} onChange={routeTypeHandler}/>
        <TextField required variant="filled" label="Email" name="email" value={email} onChange={emailHandler}/>
        <TextField required variant="filled" label="Phone" name="phone" value={phone} onChange={phoneHandler} />
        <TextField required variant="filled" label="USDOT Number" name="dot" value={dotNum} onChange={dotNumHandler}/>
        <p>
          Shepherd's Auto Transport will use your information to contact you about our products
          and services. You may unsubscribe from these communications at any
          time.
              </p>
              <Button type="submit" primary={true}>Submit</Button>
      </CarrForm>
    </CarrierFormWrap>
  );
};

export default CarrierForm;
