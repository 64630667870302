import {
  SideBarContainer,
  SideBarMenu,
  SideBarItem,
  SideBarLink,
  ButtonsWrapper,
} from "./SideBarElements";
import { RouteButton } from "../../ButtonElements";
const SideBar = ({ isOpen, toggle }) => {
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
      <SideBarMenu>
        <SideBarItem>
          <SideBarLink to="/shippers">Shippers</SideBarLink>
        </SideBarItem>
        <SideBarItem>
          <SideBarLink to="/carriers">Carriers</SideBarLink>
        </SideBarItem>
        <SideBarItem>
          <SideBarLink to="/drivers">Drivers</SideBarLink>
        </SideBarItem>
        <ButtonsWrapper>
          <RouteButton primary="true" to="/shippers/sform">
            Free Quote
          </RouteButton>
          <RouteButton primary="false" to="footer">
            Contact Us
          </RouteButton>
        </ButtonsWrapper>
      </SideBarMenu>
    </SideBarContainer>
  );
};

export default SideBar;
